import React from 'react';

const ImagePanel = (props) => {
    // console.log(props)

    return (
        <div className={props.data.inset_image === true ? "image-panel inset" : "image-panel"}>
            <img src={props.data.image.url} alt={props.data.image.alt} />
        </div>
    )
    
}

export default ImagePanel;