import React from 'react';
import {ReactComponent as Close} from '../assets/icons/close.svg';

const Popover = (props) => {

    console.log('map: ', props);
    
    return (
        <div className={"pop-over "+props.open} >
            <div className="wrapper">
                <div className="close-button" onClick={props.closeMap}><Close stroke="#000000"/></div>
                <img src={props.data.primary.image_field.url} alt={props.data.primary.image_field.alt}/>
                <a className="button-link" href={props.data.primary.link_to_pdf.url}>Download PDF</a>
            </div>
        </div>
    );

}

export default Popover;