import React from 'react';
import {ReactComponent as Logo} from '../assets/icons/keegan-alexander-logo.svg';
import {ReactComponent as Close} from '../assets/icons/close.svg';
const NavigationPanel = (props) => {
    return (
      <div className="navigation-panel mobile">
         <div className="row">
          <div className="col col-md-11 logo">
            <Logo />
          </div>
          <div className="col col-md-1 text-right menu" onClick={props.onClick}>
            <Close />
          </div>
        </div>
        <ul>
            {props.navigation.results.map((section, index) => {
              return (
                <li key={index}><a href={"/"+section.uid}>{section.data.page_title[0].text}</a></li>
              );
            })}
        </ul>
      </div>
    );
}
export default NavigationPanel
