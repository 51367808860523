import React, { useEffect, useState } from 'react';
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import {RichText, linkResolver} from 'prismic-reactjs';

const OurTeam = (props) => {

    const section = 0
    const [peopleList, setpeopleList] = useState(null)

    
    // Get the page document from Prismic
    useEffect(() => {
        const fetchData = async () => {
        const response = await client.query(
            Prismic.Predicates.at('document.type', 'people_bio'), { orderings: '[my.people_bio.page_order]', pageSize: 100}
        )
            if (response) {
                return setpeopleList(response.results)
            }
        }
        fetchData()
    }, [section])
        
    if(peopleList) {
        return(
        <div className="team-list">
            <div className="row">
             {peopleList.length > 0 ? 
                peopleList.map((item, index) =>{
                    return (
                        <div className="col-md-6"  key={index}>
                            <a href={"/our-people/"+item.uid}>
                            <div className="image-box" style={{backgroundImage: "url("+item.data.body[0].items[0].background_image.url+")"}}>
                            </div>
                            <RichText render={item.data.body[0].items[0].banner_title} linkResolver={linkResolver} /> 
                                <p>{item.data.body[0].items ? item.data.body[0].items[0].link_text : null}</p>
                                {item.data.body[1].primary.title.map((email, index) => {
                                    if(email.text.indexOf("@") >-1) {
                                        return (
                                            <p key={index}>{email.text}</p>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })}
                            </a>
                        </div>
                    )
                })
            : null }
            </div>
        </div>
        );
    }
    else {
        return null
    }
}

export default OurTeam;