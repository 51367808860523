import React from 'react'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration.js'

const Accordian = (props) => {
    // console.log(props)
    const showItem = (props) =>{ 
       props.target.childNodes[1].classList.toggle("open")
       props.target.childNodes[0].childNodes[1].childNodes[0].classList.toggle("open")
    }
    return (
        <div className="accordian-area">
            {props.data.items.map((item, index) => {
                return (
                    <div className="accordian" key={index} onClick={item.description.length > 0 ? item.description[0].text !=="" ? showItem : null : null}>
                        <div className="row" style={{position: 'relative', zIndex: -1}}>
                            <div className="col-10 col-sm-11 col-md-11">
                                <h2>{item.subject}</h2>
                            </div>
                            <div className="col-1 col-sm-1 col-md-1" >
                            {item.description.length > 0 ? item.description[0].text !=="" ? 
                                <img className="accordian-icon" src="/images/accordian-down.svg" alt="+" />
                                :null : null}
                            </div>
                        </div>
                        <div className="content">
                            <div className="intro">
                                <RichText render={item.description} linkResolver={linkResolver} /> 
                            </div>
                            {item.specalists[0] ? item.specalists[0].text !=="" ? 
                            <div className="specialist">
                                <p className="heading">Specialists:</p>
                                <RichText render={item.specalists} linkResolver={linkResolver} /> 
                            </div>
                            : null : null}
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default Accordian;