import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const TwoColumnPanel = (props) => {
    // console.log(props)

    return (
        <div className={props.data.add_margin_top === true ? "two-column-panel margin-top" :"two-column-panel"}>
            <div className="row">
                <div className="col-md-4">
                    <RichText render={props.data.title} linkResolver={linkResolver} /> 
                </div>
                <div className="col-md-8">
                    <RichText render={props.data.description} linkResolver={linkResolver} /> 
                    {window.location.href.indexOf("contact-us") >-1 ?
                        <h6><a href="https://goo.gl/maps/tmfu49F3ajpZKnsi7" rel="noopener noreferrer" target="_blank" >View in maps</a> 
                        <span onClick={props.closeMap}>Car parking directions</span></h6>
                    : null}
                </div>
            </div>
        </div>
    );
}

export default TwoColumnPanel;  