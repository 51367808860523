import React from 'react';
import {ReactComponent as Logo} from '../assets/icons/keegan-alexander-logo.svg';
import {ReactComponent as Menu} from '../assets/icons/menu-bars.svg';

const Header = (props) => {
    return(
      <header className={"header-nav " + props.stickyState + " " + props.mode}>
        <div className="header-content">
          <div className="row">
            <div className="col col-lg-3 col-sm-9">
              <a href="/" className="icon-home">
                <Logo  />
              </a>
            </div>
            <div className="col col-lg-9 col-sm-3 text-right">
              <ul className="navigation desktop">
                {props.navigation.results.map((item, index)=>{
                  if(item.uid !== "home") {
                    return (
                      <li className={item.uid === props.currentPage ? "active" : null } key={index}><a href={"/"+item.uid}>{item.data.page_title[0].text}</a></li>
                    )
                    }
                    else { 
                      return null;
                    }
                  })}
              </ul>
              <div className="menu mobile" onClick={props.onClick}><Menu /></div>
            </div>
          </div>
        </div>
      </header>
    );
}

export default Header;