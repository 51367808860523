import React, { useEffect, useState } from 'react';
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import Moment from 'moment'
import _ from 'lodash'

const ArticlesList = (props) => {

    const section = 0
    const [articleList, setArticleList] = useState(null)

    // Get the page document from Prismic
    useEffect(() => {
        const fetchData = async () => {
        const response = await client.query(
            Prismic.Predicates.at('document.type', 'blog_article'), { orderings: '[my.blog_article.last_publication_date desc]'}
        )
            if (response) {
                console.log(response.results)
                setArticleList(response.results)
            }
        }
        fetchData()
    }, [section])

    const filterArticles= (e)=> {
        let filters = document.getElementsByClassName("filter");
        for(let i=0; i<filters.length; i++) {
            filters[i].classList.remove("on")
        }
        e.target.classList.toggle("on");
        let selected = document.getElementsByClassName(e.target.dataset.name);
        let all = document.getElementsByClassName("article-block");

        for(let i=0; i<all.length; i++) {
            all[i].classList.add("hide")
        }
        for(let i=0; i<selected.length; i++) {
            selected[i].classList.remove("hide")
        }
    }

    let publishDate="";

    if (articleList) {
        let taglist=[];
        articleList.map((item, index) => {
            item.tags.map((tag, index) =>{
                taglist.push(tag)
                return null
            })
            return null
        });
        const tagbuttons =  _.uniq(taglist).sort();

        return (
            <div className="article-list">
                <div className="tag-filter">
                    <div className="filter" data-name="article-block" onClick={(e)=>filterArticles(e)}>
                         All
                    </div>
                    {tagbuttons.map((tag, index)=>{
                        return(
                            <div className="filter" data-name={tag.toLowerCase().replace(" ", "-")} onClick={(e)=>filterArticles(e)} key={index}>
                                {tag}
                            </div>
                        )
                    })}
                </div>
                <div className="row" >
                {articleList.map((item, index) =>{
                    let tagClasses=""
                    publishDate = Moment(item.data.publication_date,"YYYY-MM-DD").format("Do MMM YYYY");
                    item.tags.map((tag, index) =>{
                        tagClasses += " "+tag.replace(" ", "-");
                        return null;
                    })
                        return (
                            <div className={"article-block col-12 col-md-4 "+tagClasses.toLowerCase()} key={index}>
                                <div className="article-title">{item.data.article_title}</div>
                                <div className="publication">{publishDate}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

export default ArticlesList;