// In src/pages/Page.js
import React, { useEffect, useRef, useState,Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { client } from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import { IntroText, AlertBanner, Accordian, ImagePanel, BannerArea, OurTeam, TwoColumnPanel, ArticlesList, Header, Footer, NavigationPanel, Popover } from '../components'

const Section = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const uid = match.params.uid
  const [navItems, setNavItems] = useState(null)
  const [navFooter, setNavFooter] = useState(null)
  const [menuState, togglemenuState] = useState(false)
  const [mapState, togglemapState] = useState("")
  const [sticky, setSticky] = useState("")
  const navPanel = useRef(null)

  let dataSet =[]

  const toggleMenu = () => {
    if(menuState === true) {
      togglemenuState(false);
      navPanel.current.classList.remove("open");
    }
    else {
      togglemenuState(true)
      navPanel.current.classList.add("open");
    }
  }

  const toggleMap = () => {
    if(mapState === "open") {
      togglemapState("");
    }
    else {
      togglemapState("open")
    }
  }

  // Get the page document from Prismic
  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 60){
        setSticky("sticky")
      } else {
        setSticky("")
      }
    })
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('section', uid)

      if (result) {
        // console.log('doc', result)
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchData()
    const fetchNavItems = async () => {
      const response = await client.query(
        Prismic.Predicates.at('document.type', 'section'), { orderings: '[my.section.page_order]'}
      )
      if (response) {
        // console.log('nav: ', response)
        return setNavItems(response)
      }
    }
    fetchNavItems()
    const fetchNavFooter = async () => {
      const response = await client.query(
        Prismic.Predicates.at('document.type', 'menu_setups')
      )
      if (response) {
        // console.log('footer', response)
        return setNavFooter(response)
      }
    }
    fetchNavFooter()
  }, [uid]) // Skip the Effect hook if the UID hasn't changed

  if (doc && navFooter && navItems) {
    // console.log('page: ', doc)
    doc.data.body.map((module, index) => { 
        switch(module.slice_type) {
          case 'large_image':
            dataSet[index] = <ImagePanel data={module.primary} key={index}/>
          break;
          case 'banner_area':
            dataSet[index] = <BannerArea data={module} page={doc.uid} key={index}/>
          break;
          case 'intro_text':
            dataSet[index] = <IntroText data={module.primary} key={index}/>
          break;
          case 'two_column_panel':
            dataSet[index] = <TwoColumnPanel data={module.primary} key={index} closeMap={toggleMap}/>
          break;
          case 'practise_areas':
            dataSet[index] = <Accordian data={module} key={index}/>
          break;
          case 'blog':
            dataSet[index] = <ArticlesList data={module} key={index}/>
          break;
          case 'photo_grid1':
            dataSet[index] = <OurTeam data={module} key={index}/>
          break;
          case 'popover':
            dataSet[index] = <Popover data={module} key={index} open={mapState} closeMap={toggleMap}/>
          break;
          default: 
        }
        return null;
    });
    // console.log(doc)
    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={doc.data.seo_title}/>
          <meta property="og:description" content={doc.data.seo_description} />
          <meta property="og:image" content="https://keegan.co.nz/images/keegan-alexander.jpg" />
          <meta property="og:url" content={'https://keegan.co.nz/' + doc.data.uid} />
          <meta name="twitter:card" content="https://keegan.co.nz/images/keegan-alexander.jpg" />
          <meta property="og:site_name" content={doc.data.seo_title}/>
          <meta name="twitter:image:alt" content={doc.data.seo_description}/>
          <title>{'Keegan Alexander | '+doc.data.page_title[0].text}</title>
          <body className={doc.data.background_color ==="Green" ? "green" : ""}></body>
        </Helmet>
        {doc.uid === "home" ?
          <AlertBanner data={navFooter} stickyState={sticky}/>
        : null }
        <Header navigation={navItems} currentPage={doc.uid} onClick={toggleMenu} stickyState={sticky} />
        <div className="menu" ref={navPanel}>
          <NavigationPanel navigation={navItems} onClick={toggleMenu}/>
        </div>
          {dataSet.map((module, index) => {
              return module;
          })}
          <Footer navigation={navItems} links={navFooter.results[0].data.body} />
      </Fragment>
    )
        }
    else {
      return (
        null
      // <Fragment>
      //   <div className="load-wrapper">
      //     <img className="loader" src ="/images/loader.gif" alt=""/>
      //     </div>
      // </Fragment>
      )
    }
}

export default Section