import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { Section, People, News } from './pages'
import { apiEndpoint } from './prismic-configuration'
import './assets/styles/keegan.scss';
/**
 * Main application componenet
 */
const App = (props) => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
  const repoName = repoNameArray[1]

  return (
    <Fragment>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
        <div className="wrapper">
          <BrowserRouter>
            <Switch>
              <Redirect exact from='/' to='/home' />
              <Route exact path='/:uid' component={Section} />
              <Route exact path='/our-people/:uid' component={People} />
              <Route exact path='/insights/:uid' component={News} />
            </Switch>
          </BrowserRouter>
        </div>
    </Fragment>
  )
}

export default App
