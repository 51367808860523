import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const IntroText = (props) => {
        // console.log(props)
        return (
            <div className="intro-text">
                <RichText render={props.data.intro_text} linkResolver={linkResolver} /> 
                {props.data.link_text ? 
                    <p><a href={'/'+props.data.intro_link.uid}>{props.data.link_text}</a></p>
                : null}
            </div>
            );
}

export default IntroText;