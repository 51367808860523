import React from 'react';

const Footer = (props) => {

    let footerLinks="";

    props.links.map((slice, index) =>{
        if(slice.slice_type =="footer_links") {
            footerLinks = slice;
        }
    });
   
    return (
        <footer>
            <div className="nav-area">
                <div className="row">
                    <div className="col-md-5 col-lg-5">
                        <ul>
                            <li><a href={footerLinks.items[0].link.url} >{footerLinks.items[0].link_text}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <ul>
                            <li><a href={footerLinks.items[1].link.url} >{footerLinks.items[1].link_text}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-5 col-lg-3 text-right desktop">
                        <ul>
                            <li>{footerLinks.items[2].link_text}</li>
                        </ul>
                    </div>
                    <div className="col-md-5 mobile">
                        <ul>
                            <li>{footerLinks.items[2].link_text}</li>
                        </ul>
                    </div>
                </div>
            </div>
      </footer>
    );

}

export default Footer;