import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration'
import {ReactComponent as CloseBanner} from '../assets/icons/close-banner.svg';

const AlertBanner = (props) => {

  const [alert,setAlert] = useState(true)
  let bannerLinks="";

  props.data.results[0].data.body.map((slice, index) =>{
    if(slice.slice_type =="alert_banner") {
      bannerLinks = slice;
    }
});

if(bannerLinks.primary.show_banner_ === true && alert === true) {
    return(
      <div className={"alert-banner "+props.stickyState}>
          <RichText render={bannerLinks.primary.banner_content} linkResolver={linkResolver} /><CloseBanner className="close-banner" onClick={()=>setAlert(false)}/>
      </div>
    );
    }
    else return null;
}

export default AlertBanner;