import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const BannerArea = (props) => {

    console.log(props)
    let showControls = false;
    let short = false;

    if(props.data.length > 1) {
        showControls = true;
    }

    if(props.data.primary.short_form === true) {
        short="short";
    }

 
    return (
    <div className={props.type+ " "+props.page+" banner "+short}>
        <Carousel indicators={showControls}>
            {props.data.items.map((slide, index) => {
                let bannerImage = slide.background_image.url;
                let newImageSpecs = `${bannerImage}&q=100`

                    return (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100 slide-height"
                                src={newImageSpecs}
                                alt={slide.banner_title[0].text}
                            />
                            <div className="content-area">
                                <div className="holder">
                                    <div className="content-text">
                                        <h1>{slide.banner_title[0].text}</h1>
                                        <p><a href={slide.banner_link.url}>{slide.link_text}</a></p>
                                        <p>{slide.degrees}</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    )
                }) }
            </Carousel>
        </div>
    );
}

export default BannerArea;